import type { IPictureElement } from '../../../types/pictures'
import { useEffect, useContext } from 'react'
import { ArticleCard } from '../../atoms/ArticleCard'
import { CarouselArticleItem } from '../../atoms/CarouselArticleItem'
import { PlayerVideo } from '../../atoms/PlayerVideo'
import { Carousel } from '../../molecules/Carousel'
import { PreloadPictures } from '../../atoms/PreloadPictures'
import { ViewportCondition } from '../../atoms/ViewportCondition/ViewportCondition'
import { ARTICLE_ICON_VIDEO } from '../../../constants/components'
import { DIRECT_BANNER_HEIGHT, PLAYER_RATIO_PIP } from '../../../constants/player'
import { HEADLINES_ID } from '../../../constants/components'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { mixins, theme } from '../../../styles'
import { useViewport } from '../../../hook/useViewport'
import { link } from '../TopicHeader/TopicHeader.fixtures';

export interface IHeadlines {
  elementList: Array<{
    hasMultiLive: boolean
    icon: string
    link: string
    pictures?: {
      lazyload: boolean
      elementList: IPictureElement[]
      copyright: string
    }
    title: string
  }>
  liveId: string
}

export function Headlines({ elementList, liveId }: IHeadlines): JSX.Element {
  const [leftArticle, middleArticle, topRightArticle, bottomRightArticle] = elementList
  const { isTagCommanderReady, hit } = useContext(TagCommanderContext)
  const { isMobile } = useViewport()

  const handleOnClick = () => {
    hit(
      {
        screen_clickableElementName: `clic-bloc_headlines`,
      },
      { isClickEvent: true },
    )
  }

  const handleMobileIndexChange = (index: number) => {
    const allElements = document.querySelectorAll(
      '.Headlines__Mobile .Carousel .swiper-wrapper .ArticleCard__Content',
    )
    const element = document.querySelector(
      `.Headlines__Mobile .Carousel .swiper-wrapper .ArticleCard__Content-${index}`,
    )

    // making sure carousel ends animation before zooming in
    setTimeout(() => {
      allElements.forEach((element) =>
        element.classList.remove('ArticleCard__Carousel__Img__Animation'),
      )
      element.classList.add('ArticleCard__Carousel__Img__Animation')
    }, 300)
  }

  useEffect(() => {
    if (isTagCommanderReady) {
      hit({})
    }
  }, [isTagCommanderReady])

  return (
    <>
      {elementList?.map(({ pictures }, index) => (
        <PreloadPictures key={index} elementList={pictures?.elementList} />
      ))}
      <div className="Headlines__Mobile">
        <Carousel pagination="mobile" onIndexChange={handleMobileIndexChange}>
          {elementList?.map((element, index) => (
            <CarouselArticleItem
              key={index}
              index={index}
              title={element?.title}
              link={isMobile ? element.link : null}
              pictures={element.pictures}
              isVideo={element?.icon === ARTICLE_ICON_VIDEO}
              hasMultiLive={element?.hasMultiLive}
              onClick={handleOnClick}
              picturePriority={index === 0 ? 'high' : null}
              pictureImportance={index === 0 ? 'high' : null}
              isHeadlines
            />
          ))}
        </Carousel>
      </div>

      <div className="Headlines__Desktop" id={HEADLINES_ID}>
        <div className="Headlines__Desktop__Column flex flex-column">
          {leftArticle && (
            <ArticleCard
              {...leftArticle}
              isVideo={leftArticle.icon === ARTICLE_ICON_VIDEO}
              onClick={handleOnClick}
              pictureLazyload={false}
              pictureImportance="high"
              picturePriority="high"
              isHeadlines
              link={isMobile ? null : leftArticle.link}
            />
          )}
          <ViewportCondition
            visibleViewports={[theme.breakpoints.md, theme.breakpoints.lg, theme.breakpoints.xl]}
            placeHolder={<div className="Headlines__Desktop__Player_Placeholder" />}
          >
            <PlayerVideo
              videoId={liveId}
              containerId="headlines-live"
              withDirectBanner
              withPip
              pipDirection="down"
              isHiddenMobile
            />
          </ViewportCondition>
        </div>
        <div className="Headlines__Desktop__Column flex flex-column">
          {middleArticle && (
            <ArticleCard
              {...middleArticle}
              isVideo={middleArticle.icon === ARTICLE_ICON_VIDEO}
              onClick={handleOnClick}
              pictureLazyload={false}
              pictureImportance="high"
              picturePriority="high"
              isHeadlines
              link={isMobile ? null : middleArticle.link}
            />
          )}
        </div>
        <div className="Headlines__Desktop__Column flex flex-column">
          {topRightArticle && (
            <ArticleCard
              {...topRightArticle}
              isVideo={topRightArticle.icon === ARTICLE_ICON_VIDEO}
              onClick={handleOnClick}
              pictureLazyload={false}
              pictureImportance="high"
              picturePriority="high"
              isHeadlines
              link={isMobile ? null : topRightArticle.link}
            />
          )}
          {bottomRightArticle && (
            <ArticleCard
              {...bottomRightArticle}
              isVideo={bottomRightArticle.icon === ARTICLE_ICON_VIDEO}
              onClick={handleOnClick}
              pictureLazyload={false}
              pictureImportance="high"
              picturePriority="high"
              isHeadlines
              link={isMobile ? null : bottomRightArticle.link}
            />
          )}
        </div>
      </div>

      <style jsx>{`
        .Headlines__Mobile {
          display: block;
          visibility: visible;
        }

        .Headlines__Mobile :global(.swiper-wrapper .ArticleCard__Content) {
          height: 450px;
          padding-bottom: 70px;
        }

        .Headlines__Mobile :global(.PaginationProgress) {
          top: unset;
          bottom: 20px;
        }

        .Headlines__Mobile :global(img) {
          width: 100vw;
        }

        @media ${mixins.mediaQuery.tablet} {
          .Headlines__Mobile {
            display: none;
            visibility: hidden;
          }
        }

        .Headlines__Desktop {
          width: 100vw;
          height: 100%;
          min-height: calc(100vw / 1.92);
          display: none;
          visibility: hidden;
          flex-wrap: nowrap;
          background-color: var(--color-deepBlue);
        }

        @media ${mixins.mediaQuery.tablet} {
          .Headlines__Desktop {
            display: flex;
            visibility: visible;
          }
        }

        .Headlines__Desktop__Column {
          flex: 1;
        }

        .Headlines__Desktop__Column > :global(a) {
          display: flex;
          height: 100%;
        }

        .Headlines__Desktop__Player_Placeholder {
          padding-top: calc(${PLAYER_RATIO_PIP}% + ${DIRECT_BANNER_HEIGHT}px);
        }

        @media ${mixins.mediaQuery.tablet} {
          .Headlines__Desktop :global(.BlockerLayout__Icon) {
            min-width: 40px;
            max-width: 40px;
            margin-bottom: 8px;
          }

          .Headlines__Desktop :global(.BlockerLayout__Title) {
            font-size: 14px;
          }

          .Headlines__Desktop :global(.BlockerLayout__Button) {
            display: none;
          }
        }

        @media ${mixins.mediaQuery.desktop} {
          .Headlines__Desktop :global(.BlockerLayout__Icon) {
            min-width: inherit;
            max-width: inherit;
            margin-bottom: inherit;
          }

          .Headlines__Desktop :global(.BlockerLayout__Title) {
            font-size: inherit;
          }

          .Headlines__Desktop :global(.BlockerLayout__Button) {
            display: inherit;
          }
        }
      `}</style>
    </>
  )
}
